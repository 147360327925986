import React from 'react'
import { objectOf, any, bool, func } from 'prop-types'
import { currencyFormatUS } from '@helpers/string-helper'

const AddToCartRequiredAddons = ({ requiredAddon, requiredSelected, onChangeRequiredAddons, showInfoModalChange }) => (
  <>
    <div className="required-addon">
      <fieldset>
        <legend>
          Please accept or decline "<span dangerouslySetInnerHTML={{ __html: requiredAddon.title }} />"
        </legend>
        <div className="radio-option accept">
          <input
            type="radio"
            id={`Modal${requiredAddon.title.replace(/ /g, '')}`}
            name={`Modal${requiredAddon.title.replace(/ /g, '')}`}
            checked={requiredSelected}
            onChange={e => onChangeRequiredAddons(e.target.checked)}
          />
          <label htmlFor={`Modal${requiredAddon.title.replace(/ /g, '')}`} className="radio-label">
            <span className="grid-y">
              <span className="small-12" dangerouslySetInnerHTML={{ __html: requiredAddon.title }} />
              <span className="small-12 price">{currencyFormatUS(requiredAddon.price)}</span>
            </span>
          </label>
        </div>
        <div className="radio-option">
          <input
            type="radio"
            id={`ModalDecline${requiredAddon.title.replace(/ /g, '')}`}
            name={`Modal${requiredAddon.title.replace(/ /g, '')}`}
            checked={requiredSelected === false}
            onChange={e => onChangeRequiredAddons(!e.target.checked)}
          />
          <label htmlFor={`ModalDecline${requiredAddon.title.replace(/ /g, '')}`} className="radio-label">
            No, I decline {requiredAddon.decline}
          </label>
        </div>
      </fieldset>
      {requiredAddon.title.indexOf('Bunkie') > -1 && (
        <button
          type="button"
          gtm-category="bunkie-board-modal"
          gtm-action="click"
          gtm-label="cart"
          className="bunkie-board addon-learn-more"
          onClick={showInfoModalChange}
        >
          What is a bunkie board? &#9432;
        </button>
      )}
    </div>
  </>
)

AddToCartRequiredAddons.propTypes = {
  requiredAddon: objectOf(any),
  requiredSelected: bool,
  onChangeRequiredAddons: func,
  showInfoModalChange: func,
}

export default AddToCartRequiredAddons
