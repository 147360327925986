import { isValidUrl } from '@helpers/general'
import _get from 'lodash/get'

// eslint-disable-next-line import/prefer-default-export
export const warrantyRegions = {
  GUARDIAN: {
    subtitle: 'Your 3-year protection plan for when life makes a spill...  or a rip, or a chip!',
    link: _get(
      isValidUrl(process.env.GATSBY_ESC_GUARDIAN_URL),
      'href',
      'https://legal.roomstogo.com/rtg-online#guard-it-residential-furniture-3-year',
    ),
    linkText: 'Residential Furniture Protection Plan',
    preText:
      'This is a summary of Guard It Residential Furniture Protection Plan.  Please refer to the terms and conditions of the full',
    postText:
      ' for complete information including details of benefits, coverage, specific exclusions, conditions and limitations, and an arbitration agreement and class action waiver.  Protection plans are underwritten by Continental Casualty Company and provided by CNA Warranty Services Inc., and administered by Guardian Protection Products, INc.  Not all products and services are available in every jurisdiction.',
  },
  FORTEGRA: {
    subtitle: `Powered by Fortegra® - Your 3-year protection plan for when life makes a spill...  or a rip, or a chip!`,
    link: _get(
      isValidUrl(process.env.GATSBY_ESC_FORTEGRA_URL),
      'href',
      'https://legal.roomstogo.com/#contract-s1dgjope',
    ),
    linkText: 'Guard It Service Contract',
    preText:
      'This is a summary of Guard It Service Contract - Furniture Protection Plan.  The Guard It Service Contract is optional, cancellable (see terms) and in no way required to obtain credit.  Please refer to the terms and conditions of the full ',
    postText:
      ' for complete information including details on coverages, limitations and exclusions.  Note that any product used ina  commercial setting is excluded.  Fortegra&copy; is the marketing name for the service contract operations of the subsidiaries of Fortegrra Financial Corporation.  Products and services are provided by 4Warranty Corporation, or Lyndon Southern Insurance Company (in FL: Lic. No.: FL-03698), each of which are subsidiaries of Fortegra Financial Corporation.  Not all products and services are avialable in every jurisdiction.',
  },
}
