import PropTypes from 'prop-types'

class CatalogAvailabilityModel {
  constructor(values = {}) {
    this.FL = values?.FL || false
    this.SE = values?.SE || false
    this.TX = values?.TX || false
    this.OOM = values?.OOM || false
  }
}

const CatalogAvailabilityModelPropType = PropTypes.shape({
  FL: PropTypes.bool,
  SE: PropTypes.bool,
  TX: PropTypes.bool,
  OOM: PropTypes.bool,
})

export { CatalogAvailabilityModel, CatalogAvailabilityModelPropType }
