import React from 'react'
import PropTypes from 'prop-types'
import { sentryLogger } from '@helpers/sentry-logger'
import shieldImg from '@assets/images/shield.svg'
import { currencyFormatUS } from '@helpers/string-helper'

const handleShieldClick = () => {
  sentryLogger({
    captureMessage: {
      type: 'text',
      message: `UserConfusion - UI Play button for Extended Protection Warranty`,
    },
  })
}

const WarrantyInfo = props => {
  const { price, isWarrantyEnabled, onCheckBoxChange, showWarrantyModal } = props
  return (
    <div className="addtocart-protection-plan">
      <div className="addon-title cell">Add Furniture Protection Plan</div>
      <div className="warranty-info grid-x">
        <div className="cell small-1">
          <input
            id="warrantyPlpAdd"
            type="checkbox"
            name="warranty"
            checked={isWarrantyEnabled}
            onChange={onCheckBoxChange}
          />
        </div>
        <div className="cell auto plan-info">
          <label id="warrantyPlpDesc" htmlFor="warrantyPlpAdd">
            Protect your purchase against accidental damage and defects for 3-years
          </label>
          <div>
            <button
              aria-describedby="warrantyPlpDesc"
              type="button"
              onClick={showWarrantyModal}
              onKeyDown={e => {
                if (e.key === 'space' || e.key === 'enter') {
                  showWarrantyModal()
                }
              }}
              className="info-link fake-link"
            >
              What's covered?
            </button>
          </div>
          <p>{currencyFormatUS(price)}</p>
        </div>
      </div>
    </div>
  )
}

WarrantyInfo.propTypes = {
  price: PropTypes.number,
  isWarrantyEnabled: PropTypes.bool,
  onCheckBoxChange: PropTypes.func,
  showWarrantyModal: PropTypes.func,
}

export default WarrantyInfo
