import React from 'react'
import { string, object, number, arrayOf } from 'prop-types'
import { currencyFormatUS } from '@helpers/string-helper'

const AddToCartCheckmark = ({ promoDescription, promoItems, discount }) => (
  <div className="cell small-12 added-to-cart-checkmark">
    <svg className="checkmark-add-to-cart" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
      <circle className="checkmark-add-to-cart__circle" cx="26" cy="26" r="25" fill="none" />
      <path className="checkmark-add-to-cart__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
    </svg>

    <h3>{promoDescription || 'added to cart'}</h3>
    {promoItems && discount >= 50 && (
      <span className="savings-text">
        Offer Savings: <span className="savings-amount">{currencyFormatUS(discount)}</span>
      </span>
    )}
  </div>
)

AddToCartCheckmark.propTypes = {
  promoDescription: string,
  promoItems: arrayOf(object),
  discount: number,
}

export default AddToCartCheckmark
