import React from 'react'
import { object } from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { breakPoints } from '@constants/styles'
import PixleeWidget from './PixleeWidget'

// Setting the banner margin to zero
// This component gets the left and right margin from the parent as padding
// By extending the width and negative margins fixes it
const StrapiHtmlWrapper = styled.div`
  display: ${props => (props.display === 'mobile' ? 'none' : 'initial')};
  @media only screen and (max-width: ${breakPoints.medium}) {
    margin: 0 -0.9375rem;
    width: 100vw !important;
    display: ${props => (props.display === 'desktop' ? 'none' : 'initial')};
  }
`

const StrapiHtml = ({ data }) => {
  const html = data?.__typename === 'PageHtml' ? data?.HTML : data
  const display = data?.HTML?.DisplaySettings || data?.DisplaySettings
  return (
    <StrapiHtmlWrapper className="strapi-html" display={display}>
      {html?.CSS && <Helmet style={[{ type: 'text/css', cssText: html.CSS }]} />}
      {html?.Script && <Helmet script={[{ type: 'text/javascript', innerHTML: html.Script }]} />}
      {html?.HTML?.toLowerCase?.().includes('pixlee') ? (
        <PixleeWidget html={html?.HTML} />
      ) : (
        html?.HTML && <div dangerouslySetInnerHTML={{ __html: html.HTML }} />
      )}
    </StrapiHtmlWrapper>
  )
}

StrapiHtml.propTypes = {
  data: object.isRequired,
}

export default StrapiHtml

export const StrapiHtmlFragment = graphql`
  fragment StrapiHtmlFragment on StrapiHtml {
    __typename
    id
    CSS
    Script
    HTML
    DisplaySettings
  }
`
