import PropTypes from 'prop-types'

import { PricingModel, PricingModelPropType } from './pricing-model'
import { CatalogAvailabilityModel, CatalogAvailabilityModelPropType } from './catalog-availability-model'

class AddonModel {
  constructor(values = {}) {
    this.addon_required = values?.addon_required || false
    this.catalog_availability = new CatalogAvailabilityModel(values?.catalog_availability || {})
    this.category = values?.category || ''
    this.delivery_type = values?.delivery_type || ''
    this.image = values?.image || ''
    this.label = values?.label || ''
    this.pricing = new PricingModel(values?.pricing || {})
    this.quantity = values?.quantity || 0
    this.route = values?.route || ''
    this.sell_individually = values?.sell_individually || false
    this.sku = values?.sku || ''
    this.title = values?.title || ''
  }
}

const AddonModelPropType = PropTypes.shape({
  addon_required: PropTypes.bool,
  catalog_availability: CatalogAvailabilityModelPropType,
  category: PropTypes.string,
  delivery_type: PropTypes.string,
  image: PropTypes.string,
  label: PropTypes.string,
  pricing: PricingModelPropType,
  quantity: PropTypes.number,
  route: PropTypes.string,
  sell_individually: PropTypes.bool,
  sku: PropTypes.string,
  title: PropTypes.string,
})

export { AddonModel, AddonModelPropType }
