import React from 'react'
import { objectOf, bool, any, number, shape, string, func } from 'prop-types'
import { getCart, setWarrantyEnabled } from '@helpers/cart'
import { currencyFormatUS } from '@helpers/string-helper'
import { productPrice, isProductStrikeThrough, productOnSale } from '@helpers/product'
import ProductLocation from './product-location'
import ProductWarranty from './product-warranty'

const AddToCartModalProducts = ({
  product,
  promoQualifierQuantity,
  warrantyInfo: { isWarrantyEnabled, warrantyData },
  reachedCartLimit,
  stockMessage,
  showWarrantyModal,
  addToCart,
  toggleWarrantyEnabled,
  includesRequiredAddon,
  isStateEligible,
}) => {
  const onCheckBoxChange = event => {
    const isChecked = event.target.checked

    toggleWarrantyEnabled(event)
    if (includesRequiredAddon) {
      setWarrantyEnabled(product.sku, isChecked)
    } else {
      const cart = getCart()
      setWarrantyEnabled(product.sku, isChecked, cart.cartItems.length - 1)
    }
  }
  const strikethrough = isProductStrikeThrough(product)
  const isProductOnSale = productOnSale(product)
  return (
    <div className="main modal-mid grid-x grid-margin-x" key={`add-to-cart-modal-product_${product.sku}`}>
      <div className="horizontal-center cell small-12 medium-6">
        <img
          className="vertical-center modal-product-image"
          src={`${product.primary_image}${product.sku !== '83333333' ? '&h=150' : ''}`}
          alt={product.title}
        />
      </div>
      <div className="cell small-12 medium-5 modal-pricing">
        <div className="product-title" dangerouslySetInnerHTML={{ __html: product.title }} />
        <span className="product-title">
          {promoQualifierQuantity && promoQualifierQuantity > 1 && ` (${promoQualifierQuantity})`}
        </span>
        <div className="product-pricing">
          {strikethrough && (
            <p className="strikethrough">
              {currencyFormatUS(productPrice(product, strikethrough) * (promoQualifierQuantity || 1))}
            </p>
          )}
          <p className={strikethrough || isProductOnSale ? 'strikethrough-sale' : ''}>
            {currencyFormatUS(productPrice(product) * (promoQualifierQuantity || 1))}
          </p>
        </div>
        <ProductLocation addToCart={addToCart} stockMessage={stockMessage} />
        {!reachedCartLimit && warrantyData && warrantyData.price > 0 && isStateEligible && (
          <ProductWarranty
            showWarrantyModal={showWarrantyModal}
            price={warrantyData.price}
            isWarrantyEnabled={isWarrantyEnabled}
            onCheckBoxChange={onCheckBoxChange}
          />
        )}
      </div>
    </div>
  )
}

AddToCartModalProducts.propTypes = {
  product: objectOf(any),
  promoQualifierQuantity: number,
  warrantyInfo: shape({
    warrantyPrice: number,
    warrantyEnabled: bool,
    warrantyData: objectOf(any),
    isWarrantyEnabled: bool,
  }),
  reachedCartLimit: bool,
  includesRequiredAddon: bool,
  stockMessage: string,
  toggleWarrantyEnabled: func,
  showWarrantyModal: func,
  addToCart: func,
  isStateEligible: bool,
}

export default AddToCartModalProducts
