import React from 'react'
import { arrayOf, any, number, bool } from 'prop-types'
import { currencyFormatUS } from '@helpers/string-helper'

const AddToCartPromoAddons = ({
  promoItems,
  promoStrikethroughPrice,
  shouldShowStrikethroughPrice,
  promoTargetQuantity,
  discount,
}) => (
  <div className="addons">
    {promoItems.map(product => (
      <div className="active-addon modal-mid grid-x grid-margin-x" key={product.sku}>
        <div className="horizontal-center cell small-4 medium-6">
          <img
            className="vertical-center modal-product-image addon-image"
            src={`${product.image}&h=150`}
            alt={product.title}
          />
        </div>
        <div className="cell small-8 medium-6 modal-pricing">
          <div className="product-title">{product.title}</div>
          <span className="product-title">
            {promoTargetQuantity && promoTargetQuantity > 1 && ` (${promoTargetQuantity})`}
          </span>
          {promoStrikethroughPrice && shouldShowStrikethroughPrice && (
            <div className="product-pricing strikethrough">
              {currencyFormatUS(promoStrikethroughPrice * promoTargetQuantity)}
            </div>
          )}
          <div className="product-pricing sale">
            {promoStrikethroughPrice * promoTargetQuantity - discount > 0
              ? currencyFormatUS(promoStrikethroughPrice * promoTargetQuantity - discount)
              : currencyFormatUS(0)}
          </div>
        </div>
      </div>
    ))}
  </div>
)

AddToCartPromoAddons.propTypes = {
  promoItems: arrayOf(any),
  promoStrikethroughPrice: number,
  shouldShowStrikethroughPrice: bool,
  promoTargetQuantity: number,
  discount: number,
}

export default AddToCartPromoAddons
