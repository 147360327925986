import React, { useEffect } from 'react'
import { string } from 'prop-types'

const pixleeSubscribedEvents = ['photoOpened', 'ctaClicked', 'uploadComplete', 'widgetLoaded']

/**
 * @typedef {Object} PixleeWidgetProps
 * @property {string | undefined} containerId
 * @property {string | undefined} widgetId
 * @property {string[]} subscribedEvents
 */

/**
 * @param {string} html in format <div id="pixlee_widget_{widgetId}" ... ></div>
 * @return {PixleeWidgetProps}
 */
const getPixleeWidgetPropsFromHtmlString = html => {
  const searchString = ` id="`
  const i = html.indexOf(searchString) + searchString.length
  const j = html.indexOf('"', i + 1)
  const containerId = i > -1 && j > -1 && j > i ? html.substring(i, j) : undefined
  const widgetId = containerId?.substring('pixlee_widget_'.length)
  return { containerId, widgetId, subscribedEvents: pixleeSubscribedEvents }
}

const PixleeWidget = ({ html }) => {
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (html && window?.Pixlee) {
      window.Pixlee.addSimpleWidget?.(getPixleeWidgetPropsFromHtmlString(html))
      window.Pixlee.resizeWidget?.()

      const receiveMessage = e => {
        if (!e?.data) return
        let message
        try {
          message = JSON.parse(e.data)
        } catch (err) {
          return
        }
        if (message?.eventName && pixleeSubscribedEvents.includes(message.eventName)) {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({ event: 'pixlee_event', pixlee_type: message.eventName })
        }
      }
      window?.addEventListener('message', receiveMessage, false)
      return () => window?.removeEventListener('message', receiveMessage)
    }
  }, [html])
  return <div dangerouslySetInnerHTML={{ __html: html }} />
}

PixleeWidget.propTypes = {
  html: string,
}

export default PixleeWidget
