import React, { Component } from 'react'
import { func, string, bool, objectOf, any } from 'prop-types'
import classNames from 'classnames'
import { saveLocalStorage } from '@helpers/storage'
import { geoLocation } from '@helpers/geo-location'
import { taskDone } from '@helpers/aria-announce'
import LocationFields from '../../shared/location-fields'
import ProductLocationDeliveryInfo from './product-location-delivery-info'

class ProductLocationPopout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      error: false,
      addressInput: '',
    }
  }

  handleKeydown = e => {
    if (e.key === 'Escape') {
      this.btn.focus()
    }
  }

  handleClickOutside = event => {
    const {
      handlers: { closeModal },
    } = this.props
    if (
      this.node &&
      !this.node.contains(event.target) &&
      typeof event.target.className === 'string' &&
      !event.target.className.includes('location-button')
    ) {
      closeModal()
    }
  }

  async handleSubmit(event) {
    const { addressInput } = this.state
    event.preventDefault()
    const isPRZip =
      (Number(addressInput) >= 600 && Number(addressInput) <= 799) ||
      (Number(addressInput) >= 900 && Number(addressInput) <= 999)

    /* explicitly define the allowed URLs */
    const allowedUSDevHosts = ['rtg-dev.com', 'www.rtg-dev.com']
    const allowedUSProdHosts = ['roomstogo.com', 'www.roomstogo.com']

    /* grab the domain from the URL */
    let windowHost = ''
    if (typeof window !== 'undefined') {
      windowHost = window.location.hostname
    }

    /* if the user is on .com dev or prod and enters a Puerto Rico zipcode, redirect to the PR website */
    if (allowedUSDevHosts.includes(windowHost) && isPRZip) {
      window.location.href = `https://www.rtg-dev.pr?zip=${Number(addressInput)}`
    } else if (allowedUSProdHosts.includes(windowHost) && isPRZip) {
      window.location.href = `https://www.roomstogo.pr?zip=${Number(addressInput)}`
    } else {
      const err = await geoLocation(addressInput)
      if (err) {
        this.inputField.focus()
        return this.setState({
          error: true,
        })
      }
    }
    return saveLocalStorage('changeLocReturn', 'changeLocationPDPText')
  }

  toggleLocationModal = () => {
    const {
      handlers: { handleShow },
    } = this.props
    handleShow()
    try {
      taskDone(
        () => {
          this.inputField.focus()
        },
        100,
        'focusLocation',
      )
    } catch (error) {
      this.setState({ error: true })
    }
  }

  handleAddressInputChange = event => {
    this.setState({
      addressInput: event.target.value,
    })
  }

  render() {
    const {
      list,
      handlers: { closeModal },
      show,
      cart,
      product,
      shipping_address,
      handleKeydown,
    } = this.props
    const { addressInput, error } = this.state

    const setInputRef = ref => {
      this.inputField = ref
    }

    return (
      <>
        {!cart && (
          <ProductLocationDeliveryInfo
            product={product}
            toggleLocationModal={this.toggleLocationModal}
            shipping_address={shipping_address}
            list={list}
            show={show}
          />
        )}
        {show && (
          <>
            <div onClick={this.handleClickOutside} className="product-location-popout-container" role="presentation" />
            <div
              ref={node => {
                this.node = node
              }}
              className={classNames('product-location-popout', {
                error,
              })}
              onKeyDownCapture={handleKeydown}
            >
              <div className="form">
                <form onSubmit={e => this.handleSubmit(e)}>
                  <LocationFields
                    inputRef={setInputRef}
                    error={error}
                    addressInput={addressInput}
                    onChange={this.handleAddressInputChange}
                    noAutoComplete={!list}
                    id="PDP"
                  />
                </form>
              </div>
              <button type="button" className="close-modal" value="Close" onClick={closeModal}>
                <img
                  className="icon close"
                  alt=""
                  aira-hidden="true"
                  role="presentation"
                  src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23FFFFFF' d='M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z' /%3E%3C/svg%3E"
                />
                <span className="hide508">Close</span>
              </button>
            </div>
          </>
        )}
      </>
    )
  }
}

ProductLocationPopout.propTypes = {
  closeModal: func,
  list: bool,
  handlers: objectOf(func),
  show: bool,
  cart: bool,
  product: objectOf(any),
  shipping_address: string,
  handleKeydown: func,
}

export default ProductLocationPopout
