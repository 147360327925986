import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import CloseIcon from '@material-ui/icons/Close'
import { breakPoints, colors, fonts } from '@constants/styles'
import { warrantyRegions } from '@constants/static-data'
import {
  withStyles,
  Link,
  Fab,
  Grid,
  Typography,
  Box,
  DialogContentText,
  DialogContent,
  Dialog,
  useMediaQuery,
} from '@material-ui/core'
//  Assets
import ESCLogo from '../../../assets/images/esc/ESC_Modal_GuardIt_LOGO.png'
import ESCRoomshotLarge from '../../../assets/images/esc/ESC_Modal_ROOMSHOT.png'
import ESCRoomshotSmall from '../../../assets/images/esc/ESC_Modal_ROOMSHOT_MOBILE.png'
import FoodBevImage from '../../../assets/images/esc/FOOD_BEVERAGE.svg'
import BreakageImage from '../../../assets/images/esc/BREAKAGE.svg'
import StrainsImage from '../../../assets/images/esc/STAINS.svg'
import BurnsImage from '../../../assets/images/esc/BURNS.svg'
import CheckmarkImage from '../../../assets/images/esc/CHECKMARK.svg'
import FailuresImage from '../../../assets/images/esc/FAILURES.svg'
import RipsImage from '../../../assets/images/esc/RIPS_CUTS.svg'
import CalendarImage from '../../../assets/images/esc/CALENDAR.svg'
import PaperImage from '../../../assets/images/esc/PAPER.svg'
import PhoneImage from '../../../assets/images/esc/PHONE.svg'
import CameraImage from '../../../assets/images/esc/CAMERA.svg'
// eslint-disable-next-line react/prop-types
const FooterText = ({ link, linkText, preText, postText }) => (
  <FooterStyle>
    <Typography variant="caption">
      {preText}{' '}
      <Link variant="caption" href={link} target="_blank">
        {linkText}
      </Link>{' '}
      {postText}
    </Typography>
  </FooterStyle>
)
const content = {
  gridItems: [
    {
      image: FoodBevImage,
      bulletsText: ['Food or Beveage Stains', 'Liquid Marks or Rings'],
      title: 'Food/Beverage',
    },
    {
      image: StrainsImage,
      bulletsText: ['Pet Biological', 'Ball Point Pen Ink', 'Nail Polish'],
      title: 'Stains',
    },
    {
      image: RipsImage,
      bulletsText: ['Rips, Punctures, or Cuts'],
      title: 'Rips/Cuts',
    },
    {
      image: BreakageImage,
      bulletsText: ['Cracking or Peeling of Finish', 'Chips and Gouges', 'Breakage of Glass or Mirrors'],
      title: 'Breakage',
    },
    {
      image: BurnsImage,
      bulletsText: ['Minor Burns by Cigarettes, Cigars, Tobacco Pipes, or Household Items'],
      title: 'Burns',
    },
    {
      image: FailuresImage,
      bulletsText: ['Breakage of Frame and Components', 'Failure of Electrical Components'],
      title: 'Failures',
    },
  ],
}

const DialogWrapper = styled(Dialog)`
  z-index: 6000 !important;
  overflow: visible !important;
  -webkit-overflow-scrolling: unset !important;
`
const DialogContainer = styled(DialogContent)`
  padding: 8px 32px;
  background-color: #f7f7f7;
  border: 0.4em solid #dedede;
  @media only screen and (max-width: ${breakPoints.medium}) {
    padding: 0;
  }
`
const CloseButton = styled(Fab)`
  position: absolute;
  z-index: 7000;
  top: -1.5em;
  right: -2em;
  background-color: #0053a0;
  width: 4em;
  height: 4em;
  & svg {
    font-size: 3em;
  },
`
const DialogContainerText = styled(DialogContentText)`
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
  overflow: hidden;
`

const TopSection = styled(Grid)`
  position: relative;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: ${breakPoints.medium}) {
    flex-direction: column;
    align-items: center;
  }
`
const GuardItLogo = styled(Grid)`
  display: flex;
  position: absolute;
  top: 30px;
  left: 20px;
  height: 104px;
  width: 100px;
  z-index: 1000;
  img {
    height: 100px;
    width: 100px;
  }
  @media only screen and (max-width: ${breakPoints.medium}) {
    position: relative;
    top: 10px;
    left: unset;
    justify-content: center;
    align-items: center;
    & img {
      height: 70px;
      width: 70px;
    }
  }
`
const HeadingContainer = styled(Grid)`
  text-align: center;
  align-items: center;
  @media only screen and(max-width: ${breakPoints.medium}) {
    max-width: 100%;
    width: 100%;
  }
`
const HeadingText = styled(Typography)`
  font-size: 1.688rem !important;
  color: #203858;
  font-weight: 600;
  text-align: center;
  @media only screen and (max-width: ${breakPoints.small}) {
    font-size: 1.4rem !important;
  }
`
const SubheadingText = styled(Typography)`
  font-size: 1.2rem !important;
  font-weight: 600;
  text-align: center;
  @media only screen and (max-width: ${breakPoints.small}) {
    font-size: 0.875rem !important;
  }
`
const ESCRoomShot = styled(Grid)`
  position: relative;
  margin-bottom: 0;
  @media only screen and (max-width: ${breakPoints.small}) {
    padding-bottom: 0 !important;
  }
`
const Coverage = styled(Grid)`
  background-color: #fff;
  justify-content: center;
  color: rgb(32, 57, 88);
  align-items: stretch;
`
const BodyText = styled(Grid)`
  text-align: center;
  margin-bottom: 20px;
`
const Bullet = styled(Grid)`
  margin-bottom: 45px;
  align-items: center;
  @media only screen and (max-width: ${breakPoints.small}) {
    margin-bottom: 20px;
    justify-content: space-around;
  }
`
const StyledBullet = styled(Box)`
  background-color: #213959;
  width: 58%;
  clip-path: polygon(0% 0%, 90% 0, 100% 50%, 90% 100%, 0% 100%);
  height: 100px;
  display: flex;
  align-items: center;
  padding: 1em;
`
const IconContainer = styled(Grid)`
  @media only screen and (max-width: ${breakPoints.small}) {
    max-width: unset;
    min-width: 30%;
  }
`
const BulletList = styled.li`
  justify-content: flex-start;
  & > * {
    color: white;
  }
  p {
    font-size: 0.8rem;
  }
`
const FillingText = styled(Grid)`
  font-size: 1.7em;
  font-weight: 600;
  text-align: center;
  margin: 10px 0 20px;
  color: #203858;
  @media only screen and (max-width: ${breakPoints.small}) {
    font-size: 1.4rem;
  }
`
const ImageWapper = styled(Box)`
  display: flex;
  @media only screen and (max-width: ${breakPoints.small}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`
const FillingImage = styled(Grid)`
  justify-content: center;
  align-items: center;
  padding: 10px;
  text-align: center;
  display: flex;
  span {
    color: black;
    max-width: 90%;
    margin-top: 10px;
    font-size: 0.875rem;
    line-height: 1em;
  }
`
const StyledFooter = styled(Box)`
  color: black;
  @media only screen and (max-width: ${breakPoints.small}) {
    padding: 10px;
    span {
      line-height: 1.5em;
    }
  }
`
const StyledDetailGrid = styled(Grid)({
  backgroundColor: '#EFEEEF',
})
const FooterStyle = styled(BodyText)`
  margin-top: 10px;
  line-height: 0.2rem !IMPORTANT;
  color: rgb(32, 57, 88);
`

const styles = theme => ({
  paper: {
    overflow: 'visible',
  },
  paperWidthMd: {
    maxWidth: '800px',
    maxHeight: '600px',
    [theme.breakpoints.down('md')]: {
      margin: '0 15px',
    },
  },
})

/**
 * @name WarrantyModal
 * @description abstracted component for all ESC modals
 */
const WarrantyModal = ({ shouldShowModal, closeModal, region, classes }) => {
  const warrantyRegion = warrantyRegions[region]
  const { subtitle, link, linkText } = warrantyRegion

  const { gridItems } = content

  const showCheck = useMediaQuery('(max-width:1024px)') // toggle check icon
  const isDesktop = useMediaQuery('(min-width:512px)')

  return (
    <DialogWrapper
      label="Whats covered?"
      open={shouldShowModal}
      onClose={closeModal}
      maxWidth="md"
      classes={{ paper: classes.paper, paperWidthMd: classes.paperWidthMd }}
    >
      <CloseButton onClick={closeModal} size="medium" color="primary" aria-label="add">
        <CloseIcon />
      </CloseButton>
      <DialogContainer>
        <DialogContainerText>
          <Grid container direction="column" spacing={2}>
            <TopSection container>
              <GuardItLogo item xs={12}>
                <img className="esc-image" src={ESCLogo} alt="" aria-hidden="true" role="presentation" />
              </GuardItLogo>
              <HeadingContainer container direction="column" item xs={10} md={8}>
                <HeadingText variant="h1">Guard It Furniture Protection</HeadingText>
                <SubheadingText variant="h3">Peace of mind because accidents happen.</SubheadingText>
                <Typography variant="body2">{subtitle}</Typography>
              </HeadingContainer>
            </TopSection>
            <ESCRoomShot item xs={12}>
              <img
                className="esc-image"
                src={!isDesktop ? ESCRoomshotSmall : ESCRoomshotLarge}
                alt="ESCRoomshot family"
                aria-hidden="true"
                role="presentation"
              />
            </ESCRoomShot>
            {/* Coverage */}
            <Coverage item container justifyContent="center" alignItems="stretch" xs={12}>
              <HeadingText variant="h1">Coverage at a Glance</HeadingText>
              <BodyText variant="body1">
                This is a summary of the plain coverage. Please refer to the terms and conditions of the full{' '}
                <Link href={link} target="_blank">
                  {linkText}
                </Link>{' '}
                for complete information.
              </BodyText>
              {gridItems.map(({ image, bulletsText, title }) => (
                <Bullet item key={title} container xs={12} sm={6} spacing={2}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    item
                    xs={3}
                    style={{ alignItems: 'center' }}
                  >
                    <IconContainer item>
                      <img
                        height="70px"
                        width="70px"
                        className="esc-image"
                        src={image}
                        alt=""
                        aria-hidden="true"
                        role="presentation"
                      />
                    </IconContainer>
                    <Grid item>
                      <Typography variant="caption" style={{ color: 'black', width: '30%', justifyContent: 'center' }}>
                        {title}
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* Bullet List */}
                  <StyledBullet item xs={7}>
                    <ul>
                      {bulletsText.map(bullet => (
                        <BulletList key={bullet}>
                          <Typography variant="body1">&bull; {bullet}</Typography>
                        </BulletList>
                      ))}
                    </ul>
                  </StyledBullet>
                  {!showCheck && (
                    <Grid item xs={2}>
                      <img
                        src={CheckmarkImage}
                        alt="check mark"
                        height="70px"
                        width="70px"
                        aria-hidden="true"
                        role="presentation"
                      />
                    </Grid>
                  )}
                </Bullet>
              ))}
            </Coverage>

            {/* Claim Grid */}
            <StyledDetailGrid container item xs={12}>
              {/* Claim instructions */}
              <Grid item xs={12}>
                <FillingText>Filing a Claim is Easy</FillingText>
              </Grid>
              <ImageWapper>
                <FillingImage container direction="column" item xs={12} sm={3}>
                  <img
                    src={PaperImage}
                    width="125px"
                    height="105px"
                    alt="Camera"
                    aria-hidden="true"
                    role="presentation"
                  />
                  <Typography style={{ color: 'black' }} variant="caption">
                    Grab your receipt and/or plan documents
                  </Typography>
                </FillingImage>
                <FillingImage container direction="column" item xs={12} sm={3}>
                  <img
                    src={PhoneImage}
                    width="125px"
                    height="105px"
                    alt="Camera"
                    aria-hidden="true"
                    role="presentation"
                  />
                  <Typography style={{ color: 'black' }} variant="caption">
                    Call claim center or visit online claim portal
                  </Typography>
                </FillingImage>
                <FillingImage container direction="column" item xs={12} sm={3}>
                  <img
                    src={CameraImage}
                    width="125px"
                    height="105px"
                    alt="Camera"
                    aria-hidden="true"
                    role="presentation"
                  />
                  <Typography style={{ color: 'black' }} variant="caption">
                    Share photos or describe your furniture incident
                  </Typography>
                </FillingImage>
                <FillingImage container direction="column" item xs={12} sm={3}>
                  <img
                    src={CalendarImage}
                    width="125px"
                    height="105px"
                    alt="Camera"
                    aria-hidden="true"
                    role="presentation"
                  />
                  <Typography style={{ color: 'black' }} variant="caption">
                    Schedule your repair with a technician
                  </Typography>
                </FillingImage>
              </ImageWapper>
            </StyledDetailGrid>
            {/* Footer Text */}
            <StyledFooter>
              <FooterText {...warrantyRegion} />
            </StyledFooter>
          </Grid>
        </DialogContainerText>
      </DialogContainer>
    </DialogWrapper>
  )
}

WarrantyModal.propTypes = {
  closeModal: PropTypes.any,
  shouldShowModal: PropTypes.any,
  region: PropTypes.oneOf(['GUARDIAN', 'FORTEGRA']),
  classes: PropTypes.object,
}
FooterText.prototype = {
  linkTarget: PropTypes.string,
  linkText: PropTypes.string,
  preText: PropTypes.string,
  postText: PropTypes.string,
}

export default withStyles(styles)(WarrantyModal)
