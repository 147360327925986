import PropTypes from 'prop-types'

class PricingModel {
  constructor(values = {}) {
    this.default_price = values?.default_price || 0
    this.FL_0_list_price = values?.FL_0_list_price || 0
    this.FL_0_sale_price = values?.FL_0_sale_price || 0
    this.FL_1_list_price = values?.FL_1_list_price || 0
    this.FL_1_sale_price = values?.FL_1_sale_price || 0
    this.FL_2_list_price = values?.FL_2_list_price || 0
    this.FL_2_sale_price = values?.FL_2_sale_price || 0
    this.FL_3_list_price = values?.FL_3_list_price || 0
    this.FL_3_sale_price = values?.FL_3_sale_price || 0
    this.FL_4_list_price = values?.FL_4_list_price || 0
    this.FL_4_sale_price = values?.FL_4_sale_price || 0
    this.FL_5_list_price = values?.FL_5_list_price || 0
    this.FL_5_sale_price = values?.FL_5_sale_price || 0
    this.FL_6_list_price = values?.FL_6_list_price || 0
    this.FL_6_sale_price = values?.FL_6_sale_price || 0
    this.SE_0_list_price = values?.SE_0_list_price || 0
    this.SE_0_sale_price = values?.SE_0_sale_price || 0
    this.SE_1_list_price = values?.SE_1_list_price || 0
    this.SE_1_sale_price = values?.SE_1_sale_price || 0
    this.SE_2_list_price = values?.SE_2_list_price || 0
    this.SE_2_sale_price = values?.SE_2_sale_price || 0
    this.SE_3_list_price = values?.SE_3_list_price || 0
    this.SE_3_sale_price = values?.SE_3_sale_price || 0
    this.SE_4_list_price = values?.SE_4_list_price || 0
    this.SE_4_sale_price = values?.SE_4_sale_price || 0
    this.SE_5_list_price = values?.SE_5_list_price || 0
    this.SE_5_sale_price = values?.SE_5_sale_price || 0
    this.SE_6_list_price = values?.SE_6_list_price || 0
    this.SE_6_sale_price = values?.SE_6_sale_price || 0
    this.TX_0_list_price = values?.TX_0_list_price || 0
    this.TX_0_sale_price = values?.TX_0_sale_price || 0
    this.TX_1_list_price = values?.TX_1_list_price || 0
    this.TX_1_sale_price = values?.TX_1_sale_price || 0
    this.TX_2_list_price = values?.TX_2_list_price || 0
    this.TX_2_sale_price = values?.TX_2_sale_price || 0
    this.TX_3_list_price = values?.TX_3_list_price || 0
    this.TX_3_sale_price = values?.TX_3_sale_price || 0
    this.TX_4_list_price = values?.TX_4_list_price || 0
    this.TX_4_sale_price = values?.TX_4_sale_price || 0
    this.TX_5_list_price = values?.TX_5_list_price || 0
    this.TX_5_sale_price = values?.TX_5_sale_price || 0
    this.TX_6_list_price = values?.TX_6_list_price || 0
    this.TX_6_sale_price = values?.TX_6_sale_price || 0
  }
}

const PricingModelPropType = PropTypes.shape({
  default_price: PropTypes.number,
  FL_0_list_price: PropTypes.number,
  FL_0_sale_price: PropTypes.number,
  FL_1_list_price: PropTypes.number,
  FL_1_sale_price: PropTypes.number,
  FL_2_list_price: PropTypes.number,
  FL_2_sale_price: PropTypes.number,
  FL_3_list_price: PropTypes.number,
  FL_3_sale_price: PropTypes.number,
  FL_4_list_price: PropTypes.number,
  FL_4_sale_price: PropTypes.number,
  FL_5_list_price: PropTypes.number,
  FL_5_sale_price: PropTypes.number,
  FL_6_list_price: PropTypes.number,
  FL_6_sale_price: PropTypes.number,
  SE_0_list_price: PropTypes.number,
  SE_0_sale_price: PropTypes.number,
  SE_1_list_price: PropTypes.number,
  SE_1_sale_price: PropTypes.number,
  SE_2_list_price: PropTypes.number,
  SE_2_sale_price: PropTypes.number,
  SE_3_list_price: PropTypes.number,
  SE_3_sale_price: PropTypes.number,
  SE_4_list_price: PropTypes.number,
  SE_4_sale_price: PropTypes.number,
  SE_5_list_price: PropTypes.number,
  SE_5_sale_price: PropTypes.number,
  SE_6_list_price: PropTypes.number,
  SE_6_sale_price: PropTypes.number,
  TX_0_list_price: PropTypes.number,
  TX_0_sale_price: PropTypes.number,
  TX_1_list_price: PropTypes.number,
  TX_1_sale_price: PropTypes.number,
  TX_2_list_price: PropTypes.number,
  TX_2_sale_price: PropTypes.number,
  TX_3_list_price: PropTypes.number,
  TX_3_sale_price: PropTypes.number,
  TX_4_list_price: PropTypes.number,
  TX_4_sale_price: PropTypes.number,
  TX_5_list_price: PropTypes.number,
  TX_5_sale_price: PropTypes.number,
  TX_6_list_price: PropTypes.number,
  TX_6_sale_price: PropTypes.number,
})
export { PricingModel, PricingModelPropType }
