import React from 'react'
import { arrayOf } from 'prop-types'
import { currencyFormatUS } from '@helpers/string-helper'
import { getRequiredAddon, productPrice } from '@helpers/product'
import { AddonModelPropType } from '@models/api/products/addon-model'

const AddToCartAddons = props => {
  const { allActiveAddons = [], activeAddons = [] } = props
  const addons = allActiveAddons || activeAddons

  const getAddonsToDisplay = () => {
    const requiredAddons = addons?.filter(addon => addon?.addon_required)
    if (requiredAddons?.length < 1) {
      return addons
    }

    const requiredAddon = getRequiredAddon(requiredAddons)
    requiredAddon.sku = requiredAddon?.skus?.join()
    requiredAddon.isRequiredItemGroup = true

    const filteredAddons = addons?.filter(addon => !requiredAddon?.skus?.includes(addon?.sku))
    return [requiredAddon, ...filteredAddons]
  }

  return (
    <div className="addons">
      {getAddonsToDisplay().map(product => (
        <div className="active-addon modal-mid grid-x grid-margin-x" key={product.sku}>
          <div className="horizontal-center cell small-6 medium-6">
            <i className="icon checkmark" />
          </div>
          <div className="cell small-6 medium-6 modal-pricing">
            <div className="product-title">
              <span dangerouslySetInnerHTML={{ __html: product.title }} />
              {`${product.quantity > 1 ? ` (${product.quantity})` : ''}`}
            </div>
            {!product?.isRequiredItemGroup && (
              <div className="product-pricing">{currencyFormatUS(productPrice(product) * product.quantity)}</div>
            )}
            {product?.isRequiredItemGroup && <div className="product-pricing">{currencyFormatUS(product?.price)}</div>}
          </div>
        </div>
      ))}
    </div>
  )
}

AddToCartAddons.propTypes = { allActiveAddons: arrayOf(AddonModelPropType), activeAddons: arrayOf(AddonModelPropType) }

export default AddToCartAddons
