import React from 'react'
import { string, bool } from 'prop-types'
import { getFromBrowserStorage } from '@helpers/storage'

const getContentByVariant = (variant, stockMessage, stockMessageOutput, customerAssemblyRequired) => {
  let content = ''
  const stockMessageHTML = stockMessage && stockMessage.toUpperCase()
  if (stockMessage) {
    const baseContent = (
      <>
        {stockMessageOutput}
        <div dangerouslySetInnerHTML={{ __html: stockMessageHTML }} />
      </>
    )

    if (variant === 'VariantA' || variant === 'VariantB') {
      if (variant === 'VariantB' && stockMessage === 'Available Now ') {
        content = baseContent
      } else {
        content = <>{customerAssemblyRequired ? 'CUSTOMER ASSEMBLY REQUIRED.' : ''}</>
      }
    } else {
      content = baseContent
    }

    if (stockMessage.includes('vendor')) {
      content = baseContent
    }
  }

  return content
}

const ProductStockMessage = ({ stockMessage, customerAssemblyRequired, hideStockMessage }) => {
  if (hideStockMessage) {
    return null
  }

  let stockMessageOutput
  switch (stockMessage) {
    case 'Available Now ':
      stockMessageOutput = <i className="icon checkmark" />
      break
    case 'Out of Stock ' || 'Not available in your region ':
      stockMessageOutput = <i className="icon close" />
      break
    case !stockMessage || stockMessage === '':
      stockMessageOutput = <div className="loading" />
      break
    default:
      stockMessageOutput = null
  }

  const stockMessageVariant = getFromBrowserStorage('session', 'StockMessageTest')

  return (
    <div className="in-stock cell small-12">
      {getContentByVariant(stockMessageVariant, stockMessage, stockMessageOutput, customerAssemblyRequired)}
    </div>
  )
}

ProductStockMessage.propTypes = {
  stockMessage: string,
  customerAssemblyRequired: bool,
  hideStockMessage: bool,
}

export default ProductStockMessage
