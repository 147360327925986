import React from 'react'
import { string, bool } from 'prop-types'

const AddToCartStockMessage = ({ stockMessage, includesRequiredAddon, reachedCartLimit }) => {
  const unableToAdd = 'Unable to add to cart'
  const cartLimit = 'You have already added 10 of this item to your cart.'
  let outputMessage = ''
  switch (stockMessage) {
    case 'Out of Stock ' || 'Discontinued ' || 'Available Soon ':
      outputMessage = 'Product is currently out of stock.'
      break
    default:
      outputMessage = 'Product is not available in your region.'
      break
  }

  const ReachedCartLimit = () => (
    <>
      <h3 className="unable">{unableToAdd}</h3>
      <h3 className="out-of-stock">{cartLimit}</h3>
    </>
  )

  return (
    <>
      {!includesRequiredAddon &&
        !reachedCartLimit &&
        (stockMessage === 'Out of Stock ' ||
          stockMessage === 'Not available in your region ' ||
          stockMessage === 'Discontinued ' ||
          stockMessage === 'Available Soon ') && (
          <>
            <h3 className="unable">{unableToAdd}</h3>
            <h3 className="out-of-stock">{outputMessage}</h3>
          </>
        )}
      {reachedCartLimit && <ReachedCartLimit />}
    </>
  )
}

AddToCartStockMessage.propTypes = {
  stockMessage: string,
  includesRequiredAddon: bool,
  reachedCartLimit: bool,
}

export default AddToCartStockMessage
