import React from 'react'
import { bool, string, func, object } from 'prop-types'
import classNames from 'classnames'
import RTGLink from '@shared/link'
import { breakPoints } from '@constants/styles'
import styled from 'styled-components'

const ButtonsWrapper = styled.div`
  display: flex;
  @media only screen and (max-width: ${breakPoints.small}) {
    flex-direction: column-reverse;
  }
`

const AddToCartModalButtons = ({
  closeModal,
  includesRequiredAddon,
  reqAddedToCart,
  reqConfirmed,
  onAddRequiredAddon,
  requiredAddon,
  stockMessage,
}) => {
  const buttonText = `view cart 
    ${
      stockMessage !== 'Out of Stock ' && stockMessage !== 'Discontinued ' && stockMessage !== 'Available Soon '
        ? '& checkout'
        : ''
    }`
  const handleButtonClick = () => {
    onAddRequiredAddon(requiredAddon)
  }
  return (
    <div className="cell small-12">
      <ButtonsWrapper className="grid-x grid-margin-x modal-buttons">
        <div className="cell small-12 medium-6">
          <button type="button" className="continue-shopping" value="Continue Shopping" onClick={closeModal}>
            <span aria-hidden="true">&lt;</span> continue shopping
          </button>
        </div>
        <div className="cell small-12 medium-6">
          {includesRequiredAddon && !reqAddedToCart && (
            <button
              type="button"
              className={classNames('checkout', { unavailable: !reqConfirmed })}
              value="Add to cart"
              onClick={handleButtonClick}
              disabled={!reqConfirmed}
            >
              Add to cart
            </button>
          )}
          {(!includesRequiredAddon || (includesRequiredAddon && reqConfirmed && reqAddedToCart)) && (
            <>
              {window && !window.location.pathname.includes('/cart') && (
                <RTGLink
                  data={{
                    slug: '/cart',
                    title: 'Cart',
                  }}
                  className="checkout"
                >
                  {buttonText}
                </RTGLink>
              )}
              {window && window.location.pathname.includes('/cart') && (
                <button type="button" onClick={closeModal} className="checkout">
                  {buttonText}
                </button>
              )}
            </>
          )}
        </div>
      </ButtonsWrapper>
    </div>
  )
}

AddToCartModalButtons.propTypes = {
  closeModal: func,
  includesRequiredAddon: bool,
  reqAddedToCart: bool,
  reqConfirmed: bool,
  onAddRequiredAddon: func,
  requiredAddon: object,
  stockMessage: string,
}

export default AddToCartModalButtons
